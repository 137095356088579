<template>
  <div class="news-center">
    <div class="container">
      <div class="Header-item"><Header /></div>
      <div class=""><Carousel /></div>
      <div class="news-content-web">
        <div class="details-all">
          <div class="title">{{newsdetails.title}}</div>
          <div class="timedetails">
            <div class="time"><img class="img" src="../assets/icon_date.png"/>{{newsdetails.create_time}}</div>
            <div class="share"><img class="img" src="../assets/icon_share.png"/>分享</div>
          </div>
          <div class="details-centent ql-editor" v-html="newsdetails.content"></div>
          <div class = 'return' @click="getReturn">
          <img class="img" src="../assets/bluereturn.png"/>
          {{$t('lang.newcenter.return')}}</div>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import Copyright from '@/components/Copyright/Copyright.vue'

export default {
  name: 'NewsCenter',
  components: {
    Header,
    Carousel,
    Copyright
  },
  data () {
    return {
      newsdetails: {}
    }
  },
  methods: {
    // 点击返回列表
    getReturn () {
      this.$router.push({ path: 'news_center' })
    },
    getData () {
      this.$axios.post('api/news/getnewsbyid', {
        id: this.$route.query.id
      }).then(res => {
        this.newsdetails = res.data.data
      })
    }
  },
  created () {
    this.$store.dispatch('setNavIndex', 3)
    this.getData()
  }
}
</script>
<style scoped lang="less">
.news-center {
  .news-content-web {
    width: 1200px;
    max-width: 1200px;
    margin: auto;
  }
}
.container{
  .Header-item{
    position: absolute;
    z-index: 10;
    width: 100%;
  }
}
.details-all{
    width: 640px;
    margin: auto;
    .title{
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      padding-top: 60px;
      margin-bottom: 60px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: center;
    }
    .timedetails{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .time,.share{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999999;
        .img{
          width:32px;
          height:32px;
          margin-right: 3px;
        }
      }
      .share{
        font-size: 15px;
        font-weight: 400;
        color: #0DC102;
      }
    }
  .details-centent{
    overflow: hidden;
    img{
      width:100%;
    }
  }
  .return{
    font-size: 22px;
    font-weight: 500;
    color: #019EFA;
    text-align: right;
    margin-top: 65px;
    margin-bottom: 110px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img{
      width: 25px;
    }
  }
}
</style>
<style lang="less">
.details-centent /deep/  p {
    width:100%;
}
.details-centent /deep/ img {
    width:100%;
}
</style>
